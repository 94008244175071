.container {
    max-width: 600px;
  }
  
  .star {
    color: #ccc;
    transition: color 0.2s;
  }
  
  .star.filled {
    color: #ff9900;
  }
  .feedback-section-header{
    color: #4999C9;
    font-size: 12px;
  }
  .feedback-section-headers{
    color: #344054;
  }
  .form-button-group{
    display: flex;
    justify-content: end;
    gap: 10px;
  }
  .form-back-btn{
    border: 1px solid #D0D5DD;
    background-color: #fff;
    border-radius: 6px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 500;
  }
  .form-next-btn{
    border: 1px solid #4999C9;
    border-radius: 6px;
    background-color: #4999C9;
    color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 500;
  }
  .form-submit-btn{
    border: 1px solid #4999C9;
    border-radius: 6px;
    background-color: #4999C9;
    color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 500;
  }
  .form-checks {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    border: 1px solid #F1F1F1;
    justify-content: space-between;
    padding: 8px;
    border-radius: 6px;
  } 
  
  .form-checks span {
    margin-right: 0.5rem; /* Space between the name and the checkbox */
  }
  .checked-background{
    background-color: #EEF0F3;
  }
  @media (max-width: 768px) {
    .container {
      padding: 0 20px;
    }
  }

/* feedbcak progress css */
.feedback-progress-text{
  width:25%;
  font-size:12px;
  font-weight:600;
  font-family:'PlusJakartaSans'
}
.feedback-progress-parent{
  height: 5px;
  width: 100%;
  background-color:#e0e0e0;
  margin-bottom: 15px;
  border-radius: 5px;
}
.feedback-progress-child{
  border-radius: 5px;
  height: 100%;
  background-color: #3b82f6;

}
.feedback-progress-percent{
  font-size: 12px;
  font-family:'PlusJakartaSans'
}