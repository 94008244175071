.rcm-overView-Wrapper {
  width:  100%;
  margin: auto;
  padding: 10px;
}
.top-calculation {
  display: grid;
  grid-template-columns: repeat(4,1fr);
  border-bottom: 1px solid #EAEAEA;
}
.rcm-middle-charts {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: auto;
  margin-top: 30px;
  gap: 10px;
  justify-content: space-between;
}
.identified-episodes {
  border: none;
  flex: 1;
  min-height: 200px;
  background-color: #fff; 
}
.breakdown-by-speciality {
  border: none;
  flex: 1;
  background-color: #fff;
}
.rcm-middle-cards {
  display: grid;
  grid-template-columns: repeat(4,1fr);
  gap: 10px;
}
.rcm-middle-cards > div,
.top-calculation > div {
  font-size: 13px;
  padding: 15px;
  gap: 8px;
}
.rcm-middle-cards > div >div p,
.hospital-rev >div >div {
  margin-bottom: 4px;
}
/* filters */
.filters-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;  
  width: 20px;  
  color: #fff;
  background-color: #4298CD;  
  cursor: pointer; /* Change cursor to pointer to indicate clickability */
}

.filters-text span {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(360deg); /* Optional: to flip the text if needed */
  font-size: 18px; /* Adjust the font size as needed */
}
.filter-content{
  border: 1px solid rgb(170, 180, 247);
  height: 300px;  
  background-color: #4298CD;  
  color: #fff;
  position: absolute;
  right: calc(100% + 10px);
}
.back-arrow-filter:hover{
cursor: pointer;
}
.graphs-wrapper {
  display: flex;
  gap: 10px;
  position: relative;
}
.calulations-wrapper {
  border: 1px solid #EAEAEA;
  border-radius: 6px;
}
.label-color{
  color: #313131;
  font-size: 10px;
  font-weight: 700;
}
.card-data-value-color{
  color: #032F49;
  font-weight: 500;
  font-size: 20px; 
}
.arrow-percent-wrapper {
  font-size: 10px;
  color: #606770;
  font-weight: 400;
  display: flex;
  gap: 3px;
}
.arrow-percent-wrapper >span {
  color: #4298CD;
  display: flex;
  align-items: center;
}
.arrow-percent-wrapper >span.downarrow {
  color: #F36624;
}