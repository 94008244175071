.audit-details {
  width: 85%;
  margin: auto;
}

.audit-details h5 {
  display: flex;
  justify-content: space-between;
  color: #344054;
  margin-right: 10px;
  font-size: 20px;
}

.audit-main {
  border: 1px solid #E6EDF4;
  border-radius: 6px;
  box-shadow: 0px 0px 5px 1px #A4937E1A;

}

.folder-image {
  object-fit: cover;
  padding: 5px;
}

.webworkflow-crumb-wrapper {
  margin-top: 10px;
  margin-bottom: 28px;
  font-size: 14px;
  font-weight: 600;
}

.workflow-bread-crumb-first {
  color: #475467;
  text-decoration: underline;
}

.workflow-bread-crumb-first:hover {
  cursor: pointer;
}

.workflow-bread-crumb-second {
  color: #475467;
}

.audit-deatils-header-text {
  color: #344054;
  margin-bottom: 16px;
}

.audit-details-header {
  display: flex;
  align-items: center;
  gap: 15px;
  padding:14px 25px 14px 25px;
  font-size: 14px;
  background-color: #fff;
  color: #344054;
}

.status-container-values-wrapper {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  display: flex;
  border-top: 1px solid grey;
}

.audit-details-header>p {
  margin: 0;
  position: relative;
  padding: 6px 12px 6px 6px;
  font-weight: 600;
}
.insurer-identifier-parent{
  font-weight: 600;
}
.audit-details-header>p:after {
  content: "";
  border-right: 2px solid #9E9E9E;
  position: absolute;
  right: 0px;
  height: 12px;
  top: 12px;

}

p.addmission-date,
p.seperation-date {
  padding: 6px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding-right: 12px;
}

.addmission-date {
  border-radius: 2px;
  padding: 2px;
}

.seperation-date {
  border-radius: 2px;
  padding: 2px;
}
.folder-image-wrapper{
  height: 56px;
  width: 58px;
  border-radius: 50%;
  border: 1px solid #E6E6E6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.folder-icon-container {
  position: relative;
}

.folder-icon-container:after {
  content: "";
  border-right: 2px solid #9E9E9E;
  position: absolute;
  right: 0px;
  height: 12px;
  top: 12px;
}

.folder-icon-container span {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  margin-right: 10px;
  align-items: center;
  border-radius: 50%;
  background-color: #f0f0f0;
}

.folder-icon-container svg {
  width: 15px;
  height: 15px;
}

.status-container select {
  color: #606770;
  outline: none;
  border: none;
  border-radius: 5px;
}

.status-container {
  color: #606770;
  text-align: left;
  background-color: #fff;
  border: 1px solid #eaebec;
  padding: 10px 12px 10px 12px;
  border-radius: 6px;
  font-size: 14px;
}

.status-container-values {
  display: flex;
  font-size: 13px;
  color: grey;
  padding-top: 4px;
  justify-content: space-between;
  padding: 10px 1px;
  align-items: center;
}

.status-container-value-spacing {
  padding: 0 25px 0 25px;
}

.status-container-values div {
  gap: 0;
  font-size: 12px;
  margin-right: 5px;
}

.insurer-identifier {
  border-radius: 9px;
  background-color: #606770;
  padding: 4px 6px 4px 6px;
  color: #fff;
  display: inline-block;
  font-size: 11px;
}

.charge-discription {
  font-size: 14px;
  color: #475467;
  text-align: justify;
}

.charge-discription-wrapper {
  display: flex;
  gap: 20px;
  margin-right: 7px;
  align-items: center;
}

.missing-link {
  font-size: 14px;
  color: #475467;
  padding: 5px 10px 5px 10px;
  text-align: left;
  margin-bottom: 15px;
  border: 1px solid #eaeffe;
}

.missing-link img {
  margin-right: 10px;
}

.audit-description {
  display: flex;
  flex-direction: column;
  /* border-bottom: 1px solid #EAEAEA; */
  gap: 16px;
  margin-bottom: 10px;
}

.audit-description-top {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 24px;
  row-gap: 16px;
  padding: 20px 18px 18px 32px;
  border-radius: 10px;
  /* box-shadow: 0 3px 10px rgba(189, 187, 187, 0.5); */
  text-align: left;
  border: 1px solid #eaeaea;
}

.audit-values-row {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.audit-description-bottom .audit-values-row{
  border-bottom: 1px solid #eaeaea;
  padding: 20px 32px 20px 32px;
}
.audit-description-bottom {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  /* box-shadow: 0 3px 10px rgba(189, 187, 187, 0.5); */
  border-radius: 10px;
  border: 1px solid #eaeaea;
  text-align: left;
  max-height: 300px;
  overflow: auto;
}

.row-name {
  color: #344054;
  font-weight: 600;
  font-size: 14px;
}

.row-value {
  color: #344054;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 10px;
}

.toggle-next-prev {
  display: flex;
  justify-content: space-between;
  padding: 7px 0px;
  margin-top: 20px;
}

.previous-next-wrapper {
  display: flex;
  gap: 8px;
  margin-right: 25px;
  font-family: sans-serif;
  font-size: 14px;
}

.previous-btn {
  border: none;
  padding: 9px 16px;
  border-radius: 6px;
  color: #475467;
  background-color: #fff;
  border: 1px solid #EAEBEC;
}

.previous-btn:hover {
  cursor: pointer;
}

.next-btn {
  border: none;
  padding: 9px 16px;
  border-radius: 6px;
  color: #475467;
  background-color: #fff;
  border: 1px solid #EAEBEC;
}

.next-btn:hover {
  cursor: pointer;
}

.donot-flag {
  border-radius: 8px;
  color: #FFF;
  background-color: #4298CD;
  font-size: 14px;
  width: fit-content;
  padding: 10px 14px;
  border: none;
}

.donot-flag:hover {
  cursor: pointer;
}
.comments-text-wrapper{
  margin-top: 10px;
  width: 50%;
}
.comments-text-wrapper textarea{
  background-color: #e6edf4;
  border-radius: 8px;
  border: 1px solid #e6edf4;
}
.comments-text-wrapper textarea::placeholder{
  font-style: italic;
  font-size: 14px;
  color: #606770;
} 
.comments-text-wrapper textarea:focus-visible{
  outline: none;
}
.comments-text-wrapper label {
  font-size: 14px;
  padding-left: 4px;
  font-weight: 600;
  padding-bottom: 12px;
}

.type-info-wrapper {
  margin-top: 10px;
  width: 50%;
}

.type-info-wrapper label {
  padding-bottom: 12px;
  font-size: 14px;
  font-weight: 600;
}

.type-info {
  padding: 10px;
  font-size: 14px;
  background-color: #E6EDF4;
  color: #606770;
  border: 1px solid #E6EDF4;
  border-radius: 8px;
  height: 100px;
}
.type-info:focus-visible{
  outline: none;
}

/* edit audit Details modal */
.editAuditDetails-btn {
  cursor: pointer;
}

.auditrule-discription-modal {
  text-align: left;
  margin-bottom: 8px;
}

.auditrule-discription-modal input,.audit-deatils-header-text input {
  width: 100%;
  font-size: small;
}
.scroll-wrapper {
  width: 30%;
}

.view-all-codes-btn {
  border: 1px solid grey;
  border-radius: 5px;
  font-size: 12px;
  background: #E6EDF4;
}

.procedure-codes-wrapper,
.additional-diagnoses-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.procedure-code-field,
.additional-diagnoses-field {
  width: 50%;
}

.procedure-codes-wrapper {
  font-size: 12px;
  text-align: left;
}

.additional-diagnoses-wrapper {
  font-size: 12px;
  text-align: left;
}

.audit-description-top-input,
.audit-description-bottom-input {
  width: 80%;
}

.procedure-code-field-input,
.additional-diagnoses-field-input {
  width: 80%;
}

.generate-cdq-btn {
  border: none;
  background-color: #344054;
  border-radius: 6px;
  color: #fff;
  width: fit-content;
  padding: 3px 6px;
  font-size: 14px;
  margin-top: 12px;
}
.audit-comment-btn {
  border: none;
  background-color: #344054;
  border-radius: 6px;
  color: #fff;
  margin-top: 12px;
  font-size: 14px;
  padding: 3px 6px;
}
/* 
.audit-comment-btn:hover {
  background-color: #2a9fe7;
} */

.generatecdqmodal ul {
  list-style: none;
}

.generatecdqmodal ul li {
  color: green;
  margin-bottom: 15px;
}

.cdq-header-text {
  color: #032F49;
}
/* Auditdetails modal css */
.modal-body .audit-description{
  display: flex;
  flex-direction: row;
}
.modal-body .audit-description-top, .modal-body .audit-description-bottom{
  font-size: 12px;
  display: flex;
  text-align: left;
  flex-direction: column;
  width: 50%;
  gap: 3px;
  padding: initial;
  border: none;
}
.modal-body .audit-description-bottom{
  max-height: initial;
}
/* Scrollbar for audit bottom section */

.audit-description-bottom::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.audit-description-bottom::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #7A7C82; 
  border-radius: 10px;
}
 
.audit-description-bottom::-webkit-scrollbar-thumb {
  background: gray; 
  border-radius: 10px;
}

.audit-description-bottom::-webkit-scrollbar-thumb:hover {
  background: gray; 
  cursor: pointer;
}