.main-page{ 
    display: flex;
    /* border: 1px solid violet;  */
}
.audits{
    display: flex;
}
/* .sidebar{
    width: 18%;
} */
.audit-content{
    width: 100%;
}
.recall-unflag-wrapper {
    display: flex;
    justify-content: space-between;
}
.recall-search {
    width: 50%;
    position: relative;
}
.recall-search input{
    width: 100%;
}
.search-identifier-recommendations{
    position: absolute;
    width: 100%;
    max-height: 250px;
    overflow: hidden auto;
    top: 30px;
    z-index: 2;
    background-color: #fff;
}
.search-identifier-recommendations li {
    list-style: none;
}
.search-identifier-recommendation{
    background-color: #a4d0ed;
    border: 1px solid #a4d0ed;
}
.search-identifier-recommendation:hover{
    background-color: #8dcbf5;
    cursor: pointer;
}