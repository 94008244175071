.dark-container .sidebar-wrapper {
  width: 35%;
}

.sidebar-wrapper{
   /* background-color: rgb(212, 203, 203); */
   background-color: #EBFAFF;
   color: black;
   overflow: auto;
   z-index: 1; 
   font-family: Arial, sans-serif;
   max-height: 940px;
}

.sidebar-wrapper::-webkit-scrollbar {
    width: 8px;
  }
  
  .sidebar-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #7A7C82; 
    border-radius: 10px;
  }
   
  .sidebar-wrapper::-webkit-scrollbar-thumb {
    background: #8ad5f5cc; 
    border-radius: 10px;
  }
  
  .sidebar-wrapper::-webkit-scrollbar-thumb:hover {
    background: #8ad5f5cc; 
    cursor: pointer;
  }
  .search-wrapper >div{
    width: 100%;
  }
.new-chat{ 
    padding: 10px 16px 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: 600;
    font-family: sans-serif;  
    position: sticky;
    top: 0;
    z-index: 1;
    /* background-color: #d4cbcb; */
   background-color: #EBFAFF;

    cursor: pointer;
}
.new-chat:hover{ 
    /* background-color: rgb(230, 224, 224); */
   background-color: #d7ecf3;

    border-radius: 5px;
    cursor: pointer;
}
.chat-logo-img{
    width: 25px;
    height: 25px;
}
/* chat history */
.chatHistory{
    margin: 12px 5px;
    padding: 5px;
    font-family: sans-serif;
    width: 90%; 
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    text-align: left;
}
.chatHistory .conversation-name {
  width: 80%;
  display: inline-block;
  overflow: hidden;
}
.chatHistory .three-dots {
  width: 20%;
  display: none;
  text-align: end;
  /* font-size: 16px; */
  font-weight: bold;
}
.chatHistory:hover{
    background-color: #7dd2ee;
    border-radius: 5px;
    cursor: pointer;
}
.chatHistory:hover .three-dots {
  display: inline-block;
  /* line-height: 7px; */
}
.chat-options {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 5px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  padding: 5px;
  border-radius: 8px; 
  width: 100%; 
  
}
.options-name{
  margin-left: 15px;
}

.option-button {
  flex: 0 0 auto;  
  min-width: 90px;  
  text-align: center;
}



.option-button {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  width: 100%;
}

.option-button svg {
  width: 16px;
  height: 16px;
}
 
/* Enhance visibility on hover */
.option-button:hover {
  background-color:  #d4e9f1; 
  border-radius: 4px;
}
.delete-button{
  color: red;
}
.chat-conversations{
  max-width: 160px;
}
.sidebar-scroll{
  max-height: 90%;
  overflow: auto;
}
.sidebar-scroll::-webkit-scrollbar{
  display: none;
}

@media screen and (max-width: 730px) {
  .sidebar-wrapper {
    display: none;
  }
}
@media screen and (min-width: 731px) {
  .sidebar-wrapper {
    display: block !important;
    min-width: 194px;
  }
}