.audits-main{
    width: 100%;
    /* height:490px; */
    max-height: 100%;
    overflow: auto; 
    /* overflow-x: hidden; /* Hide the horizontal scrollbar */
  overflow-y: auto; 
    
}
.audits-main::-webkit-scrollbar {
  width: 8px;
}
.audits-main::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}
.audits-main th{
  padding: 18px;
}
.audits-main::-webkit-scrollbar-track {
  background: lightgrey;
}
.auditrow{
    border-bottom: 1px solid grey;
    padding-bottom:10px;
}
.scroll-wrapper{
  width: 100%; 
}
.auditrow:hover {
    background: #F3F4FF;
    cursor: pointer;
}
.auditrow.active {
  background-color: #c8cad9;
}
.row-data {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.audit-rowdate{
    display: flex;
    gap: 8px;
    font-size: xx-small;
    flex-direction: column-reverse;
    white-space: nowrap;
    align-items: center;
}
.audit-count {
    display: inline-block;
    width: 22px; 
    height: 22px;  
    border-radius: 50%;
    color: #fff;  
    text-align: center;
    padding: 3px;
    font-size: 11px;
  }
  .audit-count.p1 {
    background-color: #F38C5C;
  }
  .audit-count.p2 {
    background-color: #BB6BD9;
  }
  .audit-count.p3 {
    background-color: #032F49;
  }
  .audit-count.p4 {
    background-color: #7A7C82;
  }
  .audit-count.p5 {
    background-color: #28A671;
  }

.audit-status-btn{ 
    border-radius: 5px;
    width: fit-content;
    text-align: center;
    background-color: #F86985;
    color: #fff;
    font-size: small;
    padding: 5px;
}
.audit-status-btn.passed {
    background-color:#E4F5FF
}
.audit-status-btn.passed {
    color:#0b5482
}
.audit-status-btn.failed {
    color: #F86985
}
.audit-status-btn.failed {
    background-color: #feecef;
}
.audit-status-btn.red {
  color: #E73457;
  background-color: #F8698521;
}
.audit-status-btn.blue {
  background-color: #E4F5FF;
  color: #00395B;
}
.audit-status-btn.gray {

  color: #344054;
  background: #E9E9E9;
}
.audit-rowcontent{
  width: 63%;
}

.audit-rowcontent p{
color: grey;
font-size:small;
text-align: left;
}
.audit-rowcontent p strong{
 font-size: smaller;
}

