@font-face {
    font-family: "Founders Grotesk";
    src: url("../../common/assets/fonts/FoundersGrotesk-Regular.otf");
  }
  .landingpage-header {
    background-color: #032f49;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  .landing-header-top {
    display: flex;
    width: 70%;
    margin: auto;
    justify-content: space-between;
    padding-top: 10px;
  }
  .landing-header-bottom {
    display: flex;
    width: 70%;
    margin: auto;
    justify-content: space-between;
  }
  .landing-account-info {
    display: flex;
    gap: 12px;
    margin-right: 10px;
    color: grey;
  }
  .landingpage-user-email {
    color: #4298cd;
  }
  .landingpage-arrow-down {
    position: relative;
    cursor: pointer;
  }
  .landingpage-dropdown-content {
    display: none;
    position: absolute;
    border: 1px solid black;
    padding: 8px;
    border-radius: 4px;
    width: 180px;
    text-align: center;
    background-color: #EBFAFF;
    color: black;
    font-weight: 500;
    right: 0;
  }
  .landingpage-arrow-down:hover .landingpage-dropdown-content {
    display: block;
    cursor: pointer;
    text-align: justify;
  }
  .landing-logout-btn {
    display: flex;
    gap: 8px;
    padding-top: 3px;
    align-items: center;
  }
  .selected-companion {
    color: #fff;
    font-family: "Founders Grotesk";
    font-weight: 500;
    font-size: 30px;
  }
  .landing-companion-toggle-btn {
    color: #4298cd;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 24px;
  }
  .landing-companion-toggle-btn li {
    list-style-type: none;
    padding-bottom: 23px;
    font-size: 12px;
    cursor: pointer;
  }
  .landing-companion-toggle-btn li a {
    padding-bottom: 23px;
  }
  .landing-companion-toggle-btn a.active {
    border-bottom: 3px solid #3ebad6;
  }
  .landing-companion-toggle-btn a.active  {
      color: #fff;
  }
  .mode-color-nav{
    color: #fff;
    padding: 0 10px;
  } 
  /* switch */
  .slider.light {
    background-color: #fff;
  }
  .slider.light::before {
    background-color: #032F49;
  }